import { computed, ref } from 'vue'
import { Client } from "@/client"

const getBlogPosts = () => {
  const blogData = ref([])
  const error = ref([])

  const monthNames = [
    "januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"
  ];

  const categories = {
    main: "Algemeen", event: "Evenement", tips: "Tips & tricks"
  }

  Client.items()
    .type('blogpost')
    .toObservable()
    .subscribe(
      response => blogData.value = response.items,
      err => error.value = err.message
    )

  // const load = async () => {
  //   try {
  //     let fill = await fetch(data)
  //     if (!fill.ok) {
  //       throw Error('Geen data!')
  //     }
  //     blogposts.value = await fill.json()
  //   }
  //   catch {
  //     console.log(error.value)
  //   }
  // }

  const blogList = computed(() => blogData.value.map(blogpost => ({ 
    title: blogpost.title.value,
    summary: blogpost.summary.value, 
    category: categories[blogpost.category.value[0].codename], 
    image: blogpost.overview_image.value[0].url,
    day: blogpost.date.value.getDate(),
    month: monthNames[blogpost.date.value.getMonth()],
    year: blogpost.date.value.getFullYear(),
    slug: blogpost.slug.value 
  })))

  const errorMessage = computed(() => {
    if(error.value.length) {
      console.log(error.value)
      return 'Oeps! Er ging iets mis bij het laden van de content...'
    }
  })
  
  return {
    blogList,
    errorMessage
  }
}

export default getBlogPosts