<template>
  <div class="blog">
    <h1 class="heading__main">Blog</h1>

    <div v-if="blogList.length" class="blog__overview container">
      <template v-for="(item, index) in blogList" :key="index">
        <router-link :to="{ name: 'Blogpost', params: { slug: item.slug } }">
          <article class="blog__overview__item" :style="{ backgroundImage: 'url(' + item.image + ')' }">
            <div class="blog__overview__item__bg">
            </div>

            <span class="blog__overview__item__date">{{ item.day }} {{ item.month }} {{ item.year }}</span>

            <div class="blog__overview__item__animation">
              <div class="blog__overview__item__content">
                <span class="blog__overview__item__category">{{ item.category }}</span>

                <h2>{{ item.title }}</h2>

                <p>{{ item.summary }}</p>
              </div>

              <div class="blog__overview__item__readmore">Lees meer...</div>
            </div>
          </article>
        </router-link>
      </template>
    </div>

    <div v-if="errorMessage" class="container">{{ errorMessage }}</div>
  </div>
</template>

<script>
import getBlogPosts from '../../composables/getBlogPosts'

import './blog.scss'

export default {
  name: "Blog",
  setup() {
    const {blogList, errorMessage} = getBlogPosts()

    return {
      blogList,
      errorMessage
    }
  }
}
</script>